<template>
  <div>
    <div v-if="getCart">
      <div v-for="item in getCart.items" v-bind:key="item.id">
        <div class="flex">
          <div class="w-1/2">
            {{ item.title }} ({{ $t('price') }}:
            <price v-model="item.original_price"></price>
            )
          </div>
          <div class="w-3/12 text-right">
          </div>
          <div class="w-3/12 text-right">
            <price v-model="item.original_price"></price>
          </div>
        </div>
        <div class="flex" v-show="item.discount_amount > 0">
          <div class="w-1/2">
            {{ $t('discount') }}:
          </div>
          <div class="w-3/12 text-right">
          </div>
          <div class="w-3/12 text-right">
            -
            <price v-model="item.discount_amount"></price>
          </div>
        </div>
        <div class="flex" v-show="item.reduction_amount > 0">
          <div class="w-1/2">
            {{ $t('reduction') }}:
          </div>
          <div class="w-3/12 text-right">
          </div>
          <div class="w-3/12 text-right">
            -
            <price v-model="item.reduction_amount"></price>
          </div>
        </div>
        <div v-for="(option, index) in item.options" v-bind:key="'c'+index+option.field_id" class="flex">
          <div class="w-1/2">
            {{ option.title }} ({{ $t('price') }}:
            <price v-model="option.original_price"></price>
            )
          </div>
          <div class="w-3/12 text-right">
            <span v-if="option.amount">{{ option.quantity }}</span>
          </div>
          <div class="w-3/12 text-right">
            <price v-model="option.subtotal"></price>
          </div>
        </div>
      </div>

      <div class="w-full text-right">
        {{ $t('subtotal') }}:
        <price v-model="getCart.sub_total_price"></price>
      </div>
      <div class="w-full text-right" v-show="!getEventDetails.fees_included">
        {{ $t('fees') }}
        <price v-model="getCart.total_fees"></price>
      </div>
      <div class="w-full text-right">
        {{ $t('total') }}:
        <price v-model="getCart.total_price"></price>
      </div>
      <idle-tracking></idle-tracking>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import EventBus from '@/events/eventBus'
import IdleTracking from '@/components/event/eventRegistration/IdleTracking'

export default {
  name: 'CartDetails',
  components: {IdleTracking},
  mounted() {
    let app = this
    // @todo this removes cart re-load
    localStorage.removeItem(`cartStorage_${app.$route.params.id}`)

    // this.load()

    EventBus.$on('cart/reload', app.load)

    // This touches cart updated at field every 5 minutes
    setInterval(function () {
      if (app.getCart) {
        app.touchCart({
          event_id: app.$route.params.id
        })
      }
    }, 1000 * 60 * 5)
  },
  computed: {
    ...mapGetters('cart', ['getCart']),
    ...mapGetters('eventRegistration', ['getPriceData', 'getClubDetails']),
    ...mapGetters('event', ['getEventDetails'])
  },
  watch: {
    'getCart.items': {
      deep: true,
      handler: function () {
        EventBus.$emit('cart/itemAdded')
      }
    }
  },
  methods: {
    ...mapActions('cart', ['loadCart', 'touchCart']),
    load: function () {
      this.loadCart({
        event_id: this.$route.params.id,
        options: this.getPriceData,
        clubDetails: this.getClubDetails,
        exception_type: this.$route.params.type,
        exception_token: this.$route.params.token
      })
    }
  }
}
</script>

<style scoped>

</style>