<template>
  <div>
    <ul class="ml-2" v-show="getCurrentState == 'payment'">
      <li class="flex" v-for="step in getPaymentActionsList" v-bind:key="step.key">
        {{ step.title }} <span v-show="step.completed">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormDetails from '@/components/event/form/FormDetails'

export default {
  name: 'PaymentSteps',
  computed: {
    ...mapGetters('eventRegistration', ['getPaymentActionsList', 'getCurrentState'])
  }
}
</script>

<style scoped>

</style>