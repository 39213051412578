<template>
  <div>
    <label class="form-label">
      {{ field.title }}
    </label>
    <input
        type="number"
        :max="field.max_per_cart"
        min="0"
        v-model.number="value"
        class="form-input"
        :placeholder="field.placeholder"
    >
    <p v-if="validationException" class="text-red-600">{{ validationException }}</p>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
    <p v-if="field.helper">{{ field.helper }}</p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'QuantityFieldFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: null,
      validationException: null
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (value) {
        this.validationException = null

        if (value === '' || !Number.isInteger(value)) {
          this.validationException = this.$t('integerValidationException')
          return
        }

        if (this.value > (this.field.quantity - this.field.values_count)) {
          this.value = this.field.quantity - this.field.values_count
          this.$swal({
            title: this.$t('quantityReached'),
            text: this.$t('maximumQuantityReached', {max: this.value}),
            icon: 'error'
          })
          return
        } else if (this.value > this.field.max_per_cart) {
          this.value = this.field.max_per_cart
          this.$swal({
            title: this.$t('maximumInputValueReached'),
            text: this.$t('maximumInputValueIs', {max: this.field.max_per_cart}),
            icon: 'error'
          })
          return
        }
        if (this.value < 0) {
          this.value = 0
          this.$swal({
            title: this.$t('noNegativeValues'),
            text: this.$t('thisFieldDoesNotAcceptNegativeValues'),
            icon: 'error'
          })
          return
        }
        this.saveInputData({
          form: this.form,
          field: this.field.key,
          id: this.field.id,
          price: this.field.price?.price,
          value: this.value,
          type: 'quantity'
        })
      }
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>