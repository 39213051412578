<template>
    <div>
        <label class="form-label">
            {{ field.title }}
        </label>

        <the-mask
                :mask="['##   /   ##   /   ####']"
                placeholder="00    /    00    /    0000"
                :masked="true"
                type="text"
                v-model="value"
                class="form-input border-none"
        ></the-mask>
      <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
        {{ getErrorDetails.errors[errorKey][0] }}
      </p>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
  import {TheMask} from 'vue-the-mask'

  export default {
    name: 'DateFieldDisplay',
    components: {TheMask},
    props: ['field', 'form'],
    data: function () {
      return {
        value: ''
      }
    },
    computed: {
      ...mapGetters('eventRegistration', ['getErrorDetails']),
      errorKey: function () {
        return 'inputs.' + this.form.id + '.' + this.field.key
      },
      calculateAge() {
        let currentDate = new Date();
        let birthDate = new Date(this.value.replace(/ /gi, ''));
        let difference = currentDate - birthDate;
        let age = Math.floor(difference/31557600000);
        return age
      }
    },
    watch: {
      value: {
        deep: true,
        handler: function () {
          this.saveInputData({
            form: this.form,
            field: this.field.key,
            id: this.field.id,
            price: this.field.price,
            value: this.value.replace(/ /gi, '')
          })
          let formId = this.form.id
          this.setParentalAuth({
            [formId]: { over_18: this.calculateAge >= 18 ? true : false }
          })
        }
      }
    },
    methods: {
      ...mapActions('eventRegistration', ['saveInputData','setParentalAuth'])
    }
  }
</script>

<style scoped>

</style>