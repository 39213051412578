<template>
  <div>

    <h2>{{ $t('paymentPage') }}</h2>

    <payment-overview-page v-if="getCurrentSubStep == 'payment-overview'"></payment-overview-page>
    <payment-card-page v-if="getCurrentSubStep == 'payment-payment'"></payment-card-page>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PaymentOverviewPage from '@/components/event/eventRegistration/Details/Partials/PaymentOverviewPage'
import PaymentCardPage from '@/components/event/eventRegistration/Details/Partials/PaymentCardPage'

export default {
  name: 'PaymentPage',
  components: {PaymentCardPage, PaymentOverviewPage},
  computed: {
    ...mapGetters('cart', ['getCart']),
    ...mapGetters('event', ['getEventDetails']),
    ...mapGetters('eventRegistration', ['getInputData', 'getCurrentSubStep'])
  }
}
</script>

<style scoped>

</style>