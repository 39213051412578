<template>
    <div>
        <label class="form-label">
            {{ field.title }}
        </label>

        <div>
            <span @click="dec" class="btn btn-primary">-</span>
            <span>{{ value }}</span>
            <span @click="inc" class="btn btn-primary">+</span>
        </div>

        <p v-if="field.helper">{{ field.helper }}</p>
        <p class="mt-3">
            <img v-if="field.media[0]" :src="field.media[0].url" style="width: 200px; height: auto;">
            <img v-if="!field.media[0]" :src="field.default_image" style="width: 200px; height: auto;">
        </p>
    </div>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'MealFieldDisplay',
    props: ['field', 'form'],
    data: function () {
      return {
        value: 0
      }
    },
    watch: {
      value: {
        deep: true,
        handler: function (value) {
          this.saveInputData({
            form: this.form,
            field: this.field.key,
            id: this.field.id,
            price: this.field.price?.price,
            value: this.value,
            type: 'quantity'
          })
          // @todo probably we can add paid input addition here?
        }
      }
    },
    methods: {
      ...mapActions('eventRegistration', ['saveInputData']),
      inc() {
        if (!this.field.max_per_cart || this.value < this.field.max_per_cart) {
          this.value += 1
        }
      },
      dec() {
        if (this.value > 0) {
          this.value += -1
        }
      }
    }
  }
</script>

<style scoped>

</style>