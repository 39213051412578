<template>
    <div>
        <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa cumque deleniti dolor dolorem, doloremque
            eaque earum enim explicabo facilis iure libero maiores possimus praesentium quas quos reiciendis rerum
            voluptates voluptatum?
        </div>

      <div class="flex">
        <previous-button-action :form="form" :enabled="true"></previous-button-action>
        <next-button-action :form="form" :enabled="true"></next-button-action>
        </div>
    </div>
</template>

<script>
  import NextButtonAction from '@/components/event/eventRegistration/Details/Partials/NextButtonAction'
  import PreviousButtonAction from '@/components/event/eventRegistration/Details/Partials/PreviousButtonAction'

  export default {
    name: 'FormOverviewPage',
    props: ['form'],
    components: {NextButtonAction, PreviousButtonAction}
  }
</script>

<style scoped>

</style>