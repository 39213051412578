<template>
    <div>
        <label class="w-full inline-block">
            <input
                    type="checkbox"
                    v-model="value"
                    :placeholder="field.placeholder"
            >
            {{ getAccessoryName() }}
            <span v-if="field.price && field.price.price"><price v-model="field.price.display_price"></price></span>
        </label>
        <p v-if="field.helper">{{ field.helper }}</p>


        <div class="flex mt-3">
            <img v-for="media in field.media" :src="media.url" alt="image" v-bind:key="media.id" style="height: 150px; width: auto; margin-right: 10px;">
        </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'CheckboxFieldDisplay',
    props: ['field', 'form'],
    data: function () {
      return {
        value: ''
      }
    },
    watch: {
      value: {
        deep: true,
        handler: function () {
          this.saveInputData({
            form: this.form,
            field: this.field.key,
            id: this.field.id,
            price: this.getAccessoryPrice(),
            value: this.value
          })
        }
      }
    },
    methods: {
      ...mapActions('eventRegistration', ['saveInputData']),
      getAccessoryName() {
        return _.first(this.field.options).value
      },
      getAccessoryPrice() {
        return _.first(this.field.options).price
      }
    },
  }
</script>

<style scoped>

</style>