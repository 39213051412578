<template>
  <div class="mt-12 mr-2">
    <button type="button" @click="clickAction" class="btn" :disabled="!enabled" v-show="getShowPreviousButton">
      {{ $t('prevStep') }}
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "PreviousButtonAction",
  props: {
    enabled: {default: false},
    form: {default: null},
  },
  computed: {
    ...mapGetters('eventRegistration', ['getCurrentSubStep', 'getShowPreviousButton', 'getFirstFormId']),
  },
  methods: {
    ...mapActions('eventRegistration', ['setSubStep', 'moveToPrevForm', 'setDontShowPreviousButton']),
    clickAction() {
      let steps = this.form.data.steps
      let keys = Object.keys(steps)
      let prevIndex = keys.indexOf(this.getCurrentSubStep) - 1
      let prevItem = keys[prevIndex]
      if (prevItem) {
        if (this.getFirstFormId === this.form.id && this.getCurrentSubStep === 'contacts') {
          this.setDontShowPreviousButton()
        }
        this.setSubStep(prevItem)
      } else {
        this.moveToPrevForm()
      }
    }
  }
}
</script>

<style scoped>

</style>