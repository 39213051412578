<template>
  <div>
    <label class="form-label">
      {{ field.title }}
    </label>
    <div v-show="!this.showForm">
      <div class="dropzone" ref="parentalDocs">
        <div class="dz-message" data-dz-message>
          <i class="fa fa-cloud-upload" aria-hidden="true" style="font-size: 5em; color: #34495e; width: 60px; height: 60px">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt"
                 class="svg-inline--fa fa-cloud-upload-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 640 512">
              <path fill="currentColor"
                    d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path>
            </svg>
          </i>
          <span>Drag files to upload</span>
          <small style="display: inline-block; margin-top: 5px; margin-bottom: 5px;">or</small>
          <button type="button" class="btn btn-primary padding-10">
            Choose files
          </button>
        </div>
      </div>
      <p v-if="field.helper">{{ field.helper }}</p>

      <div class="w-full mt-4">
        <label>
          <input type="checkbox">
          I'll upload it later
        </label>
      </div>
    </div>

    <div v-show="this.showForm">{{ $t('parentalApproval') }}</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import DropzoneJs from 'dropzone'

export default {
  name: 'ParentalDocumentsUpload',
  props: ['field', 'form'],
  data: function () {
    return {
      documents: []
    }
  },
  mounted () {
    let zone = new DropzoneJs(this.$refs.parentalDocs, {
      url: this.$root.$data.API_URL + '/media-storage',
      params: {
        'collection': 'parental'
      },
      maxFilesize: 10, // MB,
      maxFiles: 1,
      resizeWidth: 1500
    })

    zone.on('success', (fileData, serverResponse) => {
      this.documents.push(serverResponse.id)
      this.saveInputData({
        form: this.form,
        field: 'parental',
        value: this.documents
      })
    })
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  },
  computed: {
    ...mapGetters('eventRegistration', ['getParentalAuth']),
    showForm() {
      if (this.getParentalAuth[this.form.id] != null) {
        return this.getParentalAuth[this.form.id]['over_18']
      }

      return false
    }
  }
}
</script>

<style scoped>
@import "~dropzone/dist/dropzone.css";

.dropzone {
  display: flex;
  justify-content: center;
  border: #008BFE 2px dashed;
  min-width: 250px;
  padding: 0;
}

.dz-message {
  margin: 1em 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.dz-message i {
  line-height: 70px !important;
}
</style>