<template>
    <div>
        <div v-if="getEventDetails.title">
            <div v-for="group in getGroups" v-bind:key="'g-'+group.id"
                 class="border border-solid border-black mb-4 p-4">
                <div class="mb-2 flex">
                    <div class="w-8/12 inline-block">
                        <h4 class="text-2xl font-medium inline-block">
                            {{ group.title }}
                        </h4>
                    </div>
                    <div class="w-4/12 inline-block text-right">
                        <span v-if="group.places_left > 0">Places: {{ group.places_left }}</span>
                    </div>
                </div>
                <div v-if="group.parent_forms.length > 0">
                    <form-details v-for="form in group.parent_forms" :form="form" v-bind:key="form.id"></form-details>
                </div>
                <div class="border border-solid border-red-500 p-8 text-center mb-4" v-else>
                    {{ group.title}} {{ $t('hasNoForms') }}
                </div>
            </div>
            <div v-for="form in getForms" v-bind:key="'f-' + form.id">
                <form-details :form="form"></form-details>
            </div>

            <div class="w-full mt-4" v-show="cartHasItems">
                <button type="button" class="btn btn-primary capitalize" @click="startRegistration">
                    {{ $t('register') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import FormDetails from '@/components/event/form/FormDetails'

  export default {
    name: 'FormList',
    components: {
      FormDetails
    },
    computed: {
      ...mapGetters('event', ['getEventDetails', 'getGroups', 'getForms']),
      ...mapGetters('cart', ['cartHasItems'])
    },
    methods: {
      ...mapActions('eventRegistration', ['loadSelectedForms']),
      startRegistration: function () {
        this.loadSelectedForms({
          event_id: this.getEventDetails.id
        })
      }
    }
  }
</script>

<style scoped>

</style>