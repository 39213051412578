<template>
    <div>
        <div v-if="getEventDetails.title">
            <h2>{{ getEventDetails.title}}</h2>
            <small>{{ getDate }}</small>
        </div>
        <div v-else>
            <h2>{{ $t('eventLoading') }}</h2>
        </div>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'EventDetails',
    computed: {
      ...mapGetters('event', ['getEventDetails']),
      getDate: function () {
        return moment(this.getEventDetails.startDateString).format(this.getEventDetails.dateFormat.js)
      }
    }
  }
</script>

<style scoped>

</style>