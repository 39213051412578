<template>
    <ul>
        <li v-for="(form, index) in state.formsList" v-bind:key="'steps' + form.id + index" class="ml-2">
            {{ form.title }}
            <ul class="ml-2" v-show="getCurrentForm == form.id">
                <li class="flex" v-for="(step, no) in form.data.steps" v-bind:key="form.id + no">
                    {{ step.title }} <span v-show="step.completed">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>
                  </span>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'FormSteps',
    props: ['state'],
    computed: {
      ...mapGetters('eventRegistration', ['getCurrentForm'])
    }
  }
</script>

<style scoped>

</style>