<template>
  <div>
    <label class="form-label">
      {{ field.title }} <span v-show="field.required">*</span>
    </label>
    <input
        type="text"
        v-model="value"
        class="form-input"
        :placeholder="field.placeholder"
    >
    <p v-if="field.helper">{{ field.helper }}</p>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
    <span v-if="field.price && field.price.price"><price v-model="field.price.display_price"></price></span>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'TextFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: ''
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.saveInputData({
          form: this.form,
          field: this.field.key,
          id: this.field.id,
          price: this.field.price?.price,
          value: this.value
        })
      }
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>