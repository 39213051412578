<template>
  <div v-if="showAllForm">
    <button type="button" class="buttons" @click="removeParticipant">-</button>
    <input readonly type="text" class="participant-count" :value="_participantCount"/>
    <button type="button" class="buttons" @click="addParticipant" v-if="showAddButton">+</button>
  </div>
</template>

<script>
import EventBus from '@/events/eventBus'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'FormSelectButtons',
  props: ['form'],
  data() {
    return {
      showAddButton: true,
      showAllForm: true
    }
  },
  computed: {
    ...mapGetters('cart', ['getCounts']),
    _participantCount() {
      return this.getCounts && this.getCounts[this.form.id] ? this.getCounts[this.form.id] : 0
    }
  },
  methods: {
    ...mapActions('cart', ['addNewCartItem', 'deleteCartItem']),
    addParticipant() {
      this.addNewCartItem({
        event_id: this.$route.params.id,
        form_id: this.form.id,
        form: this.form,
        exception_type: this.$route.params.type,
        exception_token: this.$route.params.token
      })
          .catch((resp) => {
            if (this._participantCount < 1) {
              this.showAllForm = false
            }

            if (resp.response) {
              this.$swal({
                title: resp.response.data.errors.message,
                text: resp.response.data.errors.text,
                icon: 'error'
              })

              EventBus.$emit('form-place-change-' + this.form.id)

            } else {
              this.$swal({
                title: this.$t('systemError'),
                text: this.$t('pleaseTryAgainOrContactSupport'),
                icon: 'error'
              })
            }
          })
    },
    removeParticipant() {
      this.deleteCartItem({
        event_id: this.$route.params.id,
        form_id: this.form.id
      })
    }
  }
}
</script>

<style scoped>
.buttons {
  width: 24px;
  @apply border rounded-full bg-blue-600 text-white outline-none
}

.participant-count {
  width: 50px;
  @apply border-b-2 ml-4 mr-4 text-center text-black outline-none
}
</style>