<template>
  <div>
    <label class="form-label">
      {{ field.title }}
    </label>
    <label v-for="option in field.options" v-bind:key="form+option.value" class="w-full inline-block">
      <input type="radio" v-model="value" :value="option.value">
      {{ option.label }}
      <span v-show="option.price">
              <price v-model="option.price"></price>
            </span>
    </label>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'RadioFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: ''
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        let price = null

        for (let i = 0; i < this.field.options.length; i++) {
          let option = this.field.options[i]
          if (this.value === option.value && option.price) {
            price = option.price
            break
          }
        }

        this.saveInputData({
          form: this.form,
          field: this.field.key, id: this.field.id,
          price: price,
          value: this.value
        })
        // @todo probably we can add paid input addition here?
      }
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>