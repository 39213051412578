<template>
  <div>
    <ul class="list-none p-0 m-0">
      <li v-for="state in getStatesList" v-bind:key="state.key" class="flex flex-col">
        <div class="flex">
            <span>
                {{ state.title }}
            </span>
          <span v-show="state.completed">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M5 13l4 4L19 7"/></svg>
            </span>
        </div>
        <form-steps v-if="state.key == 'forms' && !state.completed" :state="state"></form-steps>
        <payment-steps v-if="state.key == 'Payment'" :state="state"></payment-steps>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FormSteps from '@/components/event/eventRegistration/Steps/FormSteps'
import PaymentSteps from '@/components/event/eventRegistration/Steps/PaymentSteps'

export default {
  name: 'StepsList',
  components: {FormSteps, PaymentSteps},
  computed: {
    ...mapGetters('eventRegistration', ['getCurrentState', 'getStatesList'])
  }
}
</script>

<style scoped>

</style>