<template>
  <div></div>
</template>

<script>
import IdleJs from 'idle-js'
import EventBus from '@/events/eventBus'

export default {
  name: 'IdleTracking',
  data: function () {
    return {
      idle: null,
      modalShown: false,
      modalIdle: null,
      minutes: 5,
      waitMinutes: 5,
      allowedIdleTime: 10
    }
  },
  computed: {
    swalSettings: function () {
      return {
        title: this.$t('inactiveForTooLong'),
        text: this.$t('ifNoActionIsTakenPageWillReload', {minutes: this.minutes}),
        icon: 'warning'
      }
    }
  },
  watch: {
    modalShown: function () {
      if (this.modalShown) {
        this.idle.stop()
        this.modalIdle.start()
        this.timeLeftCalculation()
      } else {
        this.idle.start()
        this.modalIdle.stop()
        this.minutes = this.waitMinutes
      }
    },
    minutes: function () {
      let app = this
      app.$swal.update(this.swalSettings)
    }
  },
  mounted () {
    let app = this
    // Global activity modal
    this.idle = new IdleJs({
      idle: 1000 * 60 * app.allowedIdleTime, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: function () {
        app.$swal.fire(app.swalSettings).then(function () {
          app.modalShown = false
        })
        app.modalShown = true
      }, // callback function to be executed after idle time
      keepTracking: true, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: false // set it to true if you want to start in the idle state
    })

    // Inactivity modal reload
    this.modalIdle = new IdleJs({
      idle: 1000 * 60 * this.waitMinutes, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: function () {
        window.localStorage.setItem(`event_${app.$route.params.id}_idle_reload`, 'yes')
        window.location.reload()
      }, // callback function to be executed after idle time
      keepTracking: true, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: false // set it to true if you want to start in the idle state
    })

    if (window.localStorage.getItem(`event_${app.$route.params.id}_idle_reload`)) {
      app.$swal.fire({
        title: this.$t('inactiveForTooLong'),
        text: this.$t('pageReloadedDueToInactivity'),
        icon: 'info'
      })
      window.localStorage.removeItem(`event_${app.$route.params.id}_idle_reload`)
    }

    EventBus.$on('cart/itemAdded', () => {
      this.idle.start()
    })

    document.addEventListener('mousemove', function () {
      if (app.modalShown) {
        app.modalShown = false
        app.$swal.close()
      }
    })
  },
  destroyed () {
    EventBus.$off('cart/itemAdded')
  },
  methods: {
    timeLeftCalculation: function () {
      let app = this
      if (app.minutes > 0 && app.modalShown) {
        setTimeout(function () {
          app.minutes--
          app.timeLeftCalculation()
        }, 1000 * 60)
      }
    }
  }
}
</script>

<style scoped>

</style>