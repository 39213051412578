<template>
  <div>
    <label class="form-label">
      {{ field.title }} <span v-if="field.price">(<price :value="field.price.display_price"></price>)</span>
    </label>

    <div class="flex">
      <div>
        <div v-if="_isPaid">
          <label class="w-full inline-block">
            <input type="radio" name="buying" v-model="isBuying" :value="true">
            {{ $t('yes') }}
          </label>
          <label class="w-full inline-block">
            <input type="radio" name="buying" v-model="isBuying" :value="false">
            {{ $t('no') }}
          </label>
        </div>
      </div>
      <div>
        <div v-if="!_isPaid || isBuying">
          <label v-for="option in _options" v-bind:key="form+option.value" class="w-full inline-block">
            <input type="radio" v-model="value" :value="option.value">
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>

    <div class="flex">
      <img v-for="media in field.media" :src="media.url" alt="image" v-bind:key="media.id"
           style="height: 150px; width: auto; margin-right: 10px;">
    </div>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import _ from 'lodash'

export default {
  name: 'RadioFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: '',
      isBuying: false
    }
  },
  watch: {
    isBuying (value) {
      if (!value) {
        this.value = null
        return
      }

      this.value = _.first(this._options).value
    },
    value: {
      deep: true,
      handler: function () {
        if (!this.field.price) return
        this.saveInputData({
          form: this.form,
          field: this.field.key, id: this.field.id,
          price: this.field.price?.price,
          value: this.value
        })
        // @todo probably we can add paid input addition here?
      }
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    },
    _isPaid () {
      return this.field.price
    },
    _options () {
      return _.reject(this.field.options, o => {
        return o.hidden === '1'
      })
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>