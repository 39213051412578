<template>
  <div>
    <label class="form-label">
      {{ field.title }}
    </label>

    <div>
      <label class="w-full inline-block">
        <input type="radio" name="buying" v-model="hasMedicalConditions" :value="true">
        {{ $t('yes') }}
      </label>
      <label class="w-full inline-block">
        <input type="radio" name="buying" v-model="hasMedicalConditions" :value="false">
        {{ $t('no') }}
      </label>
    </div>
    <div v-if="hasMedicalConditions" class="mt-3">
      <label class="w-full inline-block">
        {{ $t('Conditions') }}
      </label>
      <textarea v-model="conditions" rows="5"
                class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                :placeholder="field.placeholder"></textarea>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import _ from 'lodash'

export default {
  name: 'MedicalConditionsFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      hasMedicalConditions: false,
      conditions: false
    }
  },
  created() {
    this.saveInputData({
      form: this.form,
      field: this.field.key, id: this.field.id,
      price: 0,
      value: this.hasMedicalConditions ? this.conditions : false
    })
  },
  watch: {
    hasMedicalConditions(value) {
      if (!value) {
        this.conditions = false
      } else {
        this.conditions = ''
      }
      this.saveInputData({
        form: this.form,
        field: this.field.key, id: this.field.id,
        price: 0,
        value: this.hasMedicalConditions ? this.conditions : false
      })
    },
    conditions: {
      deep: true,
      handler: function () {
        this.saveInputData({
          form: this.form,
          field: this.field.key, id: this.field.id,
          price: 0,
          value: this.hasMedicalConditions ? this.conditions : false
        })
        // @todo probably we can add paid input addition here?
      }
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>