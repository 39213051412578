<template>
  <div>
    <label class="form-label">
      {{ field.title }}
    </label>

    <select v-model="value"
            class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
      <option value="null">{{ $t('pleaseSelect') }}</option>
      <option v-for="option in field.options" :value="option.value" v-bind:key="form+option.value">
        {{ $root.currency.format(option.display_price / 100) }}
      </option>
    </select>

    <p v-if="field.helper">{{ field.helper }}</p>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
    <p v-if="field.media[0]" class="mt-3">
      <img :src="field.media[0].url" style="width: 200px; height: auto;">
    </p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SelectFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: null
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        let price = null

        for (let i = 0; i < this.field.options.length; i++) {
          let option = this.field.options[i]
          if (this.value === option.value && option.price) {
            price = option.price
            break
          }
        }

        this.saveInputData({
          form: this.form,
          field: this.field.key, id: this.field.id,
          price: price,
          value: this.value
        })
        // @todo probably we can add paid input addition here?
      }
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>