<template>
  <div class="mt-12">
    <button type="button" @click="clickAction" class="btn" :disabled="!enabled"
            :class="{'btn-disabled': !enabled, 'btn-primary': enabled}">
      {{ $t('nextStep') }}
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  name: 'NextButtonAction',
  props: {
    enabled: {default: false},
    form: {default: null},
    requiresValidation: {default: false},
    validatingFields: {default: null}
  },
  computed: {
    ...mapGetters('eventRegistration', ['getCurrentSubStep', 'getInputData', 'getFirstFormId'])
  },
  methods: {
    ...mapActions('eventRegistration', ['setSubStep', 'moveToNextForm', 'saveErrorDetails', 'setSubStepCompleted', 'setShowPreviousButton', 'setFirstFormId']),
    clickAction () {
      if (this.requiresValidation) {
        axios.post('/registration/validate/step', {
          form: this.form.id,
          fields: this.validatingFields,
          inputs: this.getInputData
        }).then((resp) => {
          this.moveToNextStepInForm()
        }).catch((resp) => {
          if (resp.response.data) {
            this.saveErrorDetails(resp.response.data)
            this.$nextTick(function() {
              // On error scroll to top of the page
              let element = document.getElementsByTagName('body')[0]
              element.scrollIntoView()
            })
          }
        })
      } else {
        this.moveToNextStepInForm()
      }
    },
    moveToNextStepInForm () {
      if (this.form) {
        let steps = this.form.data.steps
        let keys = Object.keys(steps)
        let nextIndex = keys.indexOf(this.getCurrentSubStep) + 1
        let nextItem = keys[nextIndex]
        if (nextItem) {
          // @todo send out event that current step is done
          if (this.getFirstFormId === null) {
            this.setFirstFormId(this.form.id)
          }
          this.setShowPreviousButton()
          this.setSubStepCompleted(this.getCurrentSubStep)
          this.setSubStep(nextItem)
        } else {
          // @todo send a new form open request and reset steps
          this.moveToNextForm()
        }
      } else {
        this.moveToNextForm()
      }
      this.saveErrorDetails({})
    }
  }
}
</script>

<style scoped>

</style>