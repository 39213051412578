<template>
  <div>
    <div class="mb-4">
      <label>
        <input type="checkbox" id="terms_1" placeholder="Your group name" v-model="inputs.terms_1">
        {{ $t('terms1') }}
      </label>
    </div>
    <div class="mb-4">
      <label>
        <input type="checkbox" id="terms_2" placeholder="Your group name" v-model="inputs.terms_2">
        {{ $t('terms2') }}
      </label>
    </div>
    <div class="mb-4">
      <label>
        <input type="checkbox" id="terms_3" placeholder="Your group name" v-model="inputs.terms_3">
        {{ $t('terms3') }}
      </label>
    </div>
    <next-button-action :form="form" :enabled="validForm"></next-button-action>
  </div>
</template>

<script>
import NextButtonAction from '@/components/event/eventRegistration/Details/Partials/NextButtonAction'
import {mapActions} from 'vuex'

export default {
  name: 'FormTermsPage',
  props: ['form'],
  components: {NextButtonAction},
  data: function () {
    return {
      inputs: {
        terms_1: false,
        terms_2: false,
        terms_3: false
      },
      validatedFields: []
    }
  },
  computed: {
    validForm: function () {
      return this.inputs.terms_1 && this.inputs.terms_2 && this.inputs.terms_3
    }
  },
  watch: {
    inputs: {
      deep: true,
      handler: function () {
        this.saveInputData({
          form: this.form,
          field: 'terms_1',
          value: this.inputs.terms_1
        })
        this.saveInputData({
          form: this.form,
          field: 'terms_2',
          value: this.inputs.terms_2
        })
        this.saveInputData({
          form: this.form,
          field: 'terms_3',
          value: this.inputs.terms_3
        })
      }
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>