import { render, staticRenderFns } from "./TextFieldDisplay.vue?vue&type=template&id=1fe07efd&scoped=true&"
import script from "./TextFieldDisplay.vue?vue&type=script&lang=js&"
export * from "./TextFieldDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe07efd",
  null
  
)

export default component.exports