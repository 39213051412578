<template>
  <div>
    <label class="form-label">
      {{ field.title }} <span v-show="field.required">*</span>
    </label>
    <model-select :options="options"
                  v-model="item"
                  placeholder="select item">
    </model-select>
    <p v-if="field.helper">{{ field.helper }}</p>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from 'vuex'
import {ModelSelect} from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  name: "NationalityFieldDisplay",
  props: ['field', 'form'],

  data: function () {
    return {
      options: [],
      item: {
        value: '',
        text: ''
      }
    }
  },
  components: {
    ModelSelect
  },
  watch: {
    item: {
      deep: true,
      handler: function () {
        this.saveInputData({
          form: this.form,
          field: this.field.key,
          id: this.item.value,
          price: this.field.price?.price,
          value: this.item.text
        })
      }
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData']),
    reset() {
      this.item = {}
    },
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  mounted() {
    axios.get(`nationalities`)
        .then((response) => {
          this.options = response.data.data
          if (this.field.default !== null) {
            this.item = this.options.find( ({ value }) => value ==  this.field.default)
          }
        })
  }
}
</script>

<style scoped>

</style>