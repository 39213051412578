<template>
  <div>
    <master-field-display v-for="field in form.data.fields[6]"
                          :field="field"
                          :form="form"
                          v-bind:key="form.id + field.id"></master-field-display>

    <div class="flex">
      <previous-button-action :form="form" :enabled="true"></previous-button-action>
      <next-button-action :form="form" :enabled="true"></next-button-action>
    </div>

  </div>
</template>

<script>
import NextButtonAction from '@/components/event/eventRegistration/Details/Partials/NextButtonAction'
import PreviousButtonAction from '@/components/event/eventRegistration/Details/Partials/PreviousButtonAction'
import MasterFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/MasterFieldDisplay'

export default {
  name: 'FormDocumentUpload',
  props: ['form'],
  components: {NextButtonAction, MasterFieldDisplay, PreviousButtonAction}
}
</script>

<style scoped>

</style>