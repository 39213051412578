<template>
  <div>
    <div>
      <label>I belong to a club</label>
      <div>
        <label>
          <input type="radio" v-model="belongsToClub" value="0" :disabled="exceptionUsed"> No
        </label>
        <label>
          <input type="radio" v-model="belongsToClub" value="1"> Yes
        </label>
      </div>
    </div>

    <div v-show="belongsToClub === '1'">
      <div class="mt-4">
        <label>
          <input type="radio" v-model="newClub" value="0"> Join a club
        </label>
        <label>
          <input type="radio" v-model="newClub" value="1" :disabled="exceptionUsed"> I'm the first registrant from the
          club
        </label>
      </div>
      <div class="mt-4">
        <label>{{ field.title }}</label>
        <div class="w-full flex">
          <div :class="{'w-full': newClub === '0', 'w-8/12 mr-4': newClub === '1'}">
            <input type="text" class="form-input" v-model="clubName" :placeholder="field.placeholder"
                   :disabled="exceptionUsed">
          </div>
          <div class="w-4/12" v-show="newClub === '1'">
            <button type="button" class="btn" :class="{'btn-primary': !clubNameValid, 'btn-success': clubNameValid}"
                    :disabled="clubNameValid" @click="checkClubNameValidity">
              {{ clubNameValid ? 'Success' : 'Validate' }}
            </button>
          </div>
        </div>
        <p v-if="field.helper">{{ field.helper }}</p>

        <div v-show="suggestionsList.length">
          <h3>Select existing team</h3>
          <ul class="list-disc">
            <li v-for="suggestion in suggestionsList" v-bind:key="suggestion.id" class="ml-8">
              <a href="#" @click="selectTeam(suggestion)">{{ suggestion.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  name: 'ClubFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      belongsToClub: '0',
      newClub: '0',
      clubName: null,
      suggestionsList: [],
      selectedClubID: null,
      suggestionSelected: false,
      selectedClubName: false,
      clubNameValid: false,
      exceptionUsed: false
    }
  },
  computed: {
    ...mapGetters('event', ['getEventDetails']),
    ...mapGetters('eventRegistration', ['getClubException']),
    getClubInputData: function () {
      return {
        form: this.form,
        field: this.field.key,
        id: this.field.id,
        newClub: this.newClub === '1',
        selectedClubID: this.selectedClubID,
        clubName: this.clubName,
        clubEntry: true
      }
    }
  },
  mounted () {
    let exception = this.getClubException
    if (exception.id) {
      this.exceptionUsed = true
      this.belongsToClub = '1'
      this.newClub = '0'
      this.clubName = exception.title
      this.selectedClubID = exception.id
      this.saveInputData(this.getClubInputData)
    }
  },
  watch: {
    newClub: function () {
      if (!this.exceptionUsed) {
        this.suggestionsList = this.newClub === '1' ? [] : this.suggestionsList
        if (this.newClub === '0') {
          this.loadSuggestions()
        }
      }
    },
    clubName: function () {
      if (!this.exceptionUsed) {
        if (this.newClub === '0') {
          this.loadSuggestions()
        } else {
          this.clubNameValid = false
        }
      }
    },
    clubNameValid: function () {
      this.saveInputData(this.getClubInputData)
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData']),
    loadSuggestions () {
      if (!this.suggestionSelected) {
        axios.get(`/clubs/${this.getEventDetails.id}/suggest`, {
          params: {
            club_name: this.clubName,
            form_id: this.form.data.id
          }
        })
            .then((resp) => {
              this.suggestionsList = resp.data
            })
      } else {
        this.suggestionsList = []
        this.suggestionSelected = false
      }
    },
    checkClubNameValidity () {
      if (this.clubName && this.clubName.length > 0) {
        axios.get(`/clubs/${this.getEventDetails.id}/form-validity`, {
          params: {
            club_name: this.clubName,
            form_id: this.form.data.id
          }
        })
            .then((resp) => {
              this.clubNameValid = true
            })
            .catch((resp) => {
              if (resp.response) {
                this.$swal({
                  title: resp.response.data.errors.message,
                  text: resp.response.data.errors.text,
                  icon: 'error'
                })
              }
            })
      } else {
        this.suggestionsList = []
      }
    },
    selectTeam (teamData) {
      this.clubName = teamData.title
      this.selectedClubID = teamData.id
      this.selectedClubName = teamData.id
      this.suggestionSelected = true
      this.suggestionsList = []
      this.saveInputData(this.getClubInputData)
    }
  }
}
</script>

<style scoped>

</style>