<template>
  <div>
    <div v-if="form.children.length > 0">
      <div class="flex">
        <div class="w-1/3 inline-block">
          <h5 class="text-xl">
            {{ form.title }}
          </h5>
        </div>
        <div class="w-1/3 text-right inline-block">
          <div v-if="form.places_left > 0" class="inline-block">
            Places: {{ form.places_left }} |
          </div>
          <span v-if="form.current_price && open"> {{ $t('price') }}: <price
              v-model="form.current_price.display_price "></price></span>
        </div>
        <div class="w-1/3 text-right inline-block">
          <form-select-buttons :form="form"
                               v-if="open"></form-select-buttons>
          <div v-else>{{ $t('registrationClosed') }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex">
        <div class="w-1/3 inline-block">
          <h5 class="text-xl">
            {{ form.title }}
          </h5>
        </div>
        <div class="w-1/3 text-right inline-block">
          <div v-if="form.places_left > 0" class="inline-block">
            {{ $t('places') }}: {{ placeOverride === 0 ? placeOverride : form.places_left }} |
          </div>
          <span v-if="form.current_price && open"> {{ $t('price') }}: <price
              v-model="form.current_price.display_price "></price></span>
        </div>
        <div class="w-1/3 text-right inline-block">
          <form-select-buttons :form="form"
                               v-if="open"></form-select-buttons>
          <div v-else>{{ $t('registrationClosed') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelectButtons from '@/components/event/form/FormSelectButtons'
import EventBus from '@/events/eventBus'
import {mapGetters} from 'vuex'
import _ from 'lodash'

export default {
  name: 'FormDetails',
  components: {FormSelectButtons},
  props: ['form'],
  data: function () {
    return {
      placeOverride: false
    }
  },
  computed: {
    ...mapGetters(
        'eventRegistration',
        [
          'bypassFullForm',
          'bypassClosedForm',
          'bypassGroups',
          'bypassForms',
          'bypassFullGroup'
        ]
    ),
    open() {
      return this.bypassesFull || this.form.places_left > 0 || this.form.places_left == null
    },
    bypassesFull() {
      if (this.bypassFullGroup) {
        if (this.bypassGroups.length === 0 || (this.bypassGroups.length > 0 && _.indexOf(this.bypassGroups, this.form.group_id) >= 0)) {
          return true
        }
      }
      if (this.bypassFullForm && !this.form.group_places) { // Full form should only be bypassed if there's no group places. Otherwise whole group should have bypass with forms selected.
        if (this.bypassForms.length === 0 || (this.bypassForms.length > 0 && _.indexOf(this.bypassForms, this.form.id) >= 0)) {
          return true
        }
      }
      return false
    }
  },
  created() {
    EventBus.$on('form-place-change-' + this.form.id, () => {
      this.placeOverride = 0
    })
  },
  beforeDestroy() {
    EventBus.$off('form-place-change-' + this.form.id)
  }
}
</script>

<style scoped>

</style>