<template>
  <div>
    <div class="w-full border-t pt-2 mt-2">
      <h2>{{ $t('discountCode') }}</h2>
    </div>
    <div class="w-full" v-show="this.getDiscount.amount">
      <p class="pt-4 pb-4 bg-green-100 text-center">
        {{ $t('discountCodeApplied') }}
      </p>
    </div>
    <div class="flex w-full mt-2">
      <div class="w-8/12">
        <input class="w-full border border-solid h-8" v-model="code"/>
      </div>
      <div class="w-4/12 ml-2">
        <button type="button" class="w-full text-center h-8 border bg-blue-600 text-white"
                @click="checkDiscountValidity">
          {{ $t('applyDiscount') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import axios from 'axios'

export default {
  name: 'DiscountCode',
  data: function () {
    return {
      code: null
    }
  },
  computed: {
    ...mapGetters('cart', ['getDiscount']),
    ...mapGetters('eventRegistration', ['getPriceData', 'getClubDetails'])
  },
  methods: {
    ...mapActions('cart', ['applyDiscount']),
    ...mapActions('eventRegistration', ['saveInputData']),
    checkDiscountValidity: function () {
      let app = this
      if (this.code) {
        this.applyDiscount({
          event_id: this.$route.params.id,
          options: this.getPriceData,
          clubDetails: this.getClubDetails,
          code: this.code
        })
            .then(function (resp) {
              app.$swal.fire({
                title: app.$t('discountApplied'),
                text: app.$t('discountBeingApplied'),
                icon: 'success'
              })
              app.saveInputData({
                inputs: {
                  discountCode: app.code
                }
              })
            })
            .catch(function (resp) {
              if (resp.response && resp.response.status !== 500) {
                let message = null
                if (resp.response.data.errors.code) {
                  message = resp.response.data.errors.code[0]
                } else {
                  message = resp.response.data.errors[0]
                }
                app.$swal.fire({
                  title: resp.response.data.message,
                  text: message,
                  icon: 'error'
                })
              } else {
                app.$swal.fire({
                  title: app.$t('serverError'),
                  text: app.$t('serverErrorAction'),
                  icon: 'error'
                })
              }
            })
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>