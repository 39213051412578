<template>
  <div>
    <div v-if="!hasPayingIDSelected">
      <div class="flex flex-rows-2 mt-2 space-x-4">
        <div class="w-1/2 border-2 p-4 rounded"
             v-for="(data, index) in list" v-bind:key="'i'+data.payingID"
             :class="{'border-blue-700 border-2': data.selected, 'border': !data.selected}"
             @click="select(index)"
        >
          <span>{{ data.registrant.first_name }} {{ data.registrant.last_name }} {{ data.title }}</span>
        </div>
      </div>

      <button type="button" class="btn btn-primary mt-8" :enabled="selectedIndex" @click="loadCardStuff">
        <span v-if="getCart.total_price > 0">Pay</span>
        <span v-else>Complete registration</span>
      </button>
    </div>
    <div v-else>

      <h1> Please wait while we redirect you automatically</h1>
      <!--      <button type="button" class="btn btn-primary mt-8" :enabled="selectedIndex" @click="loadCardStuff">Retry (will-->
      <!--        create new registrations, testing purposes only!!!)-->
      <!--      </button>-->

      <!--      <iframe class="w-full" style="height: 305px;" v-if="url" :src="url"></iframe>-->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import _ from 'lodash'
import axios from 'axios'

export default {
  name: 'PaymentCardPage',
  data: () => {
    return {
      list: [],
      selectedIndex: null,
      hasPayingIDSelected: false,
      url: null
    }
  },
  computed: {
    ...mapGetters('cart', ['getCart']),
    ...mapGetters('event', ['getEventDetails']),
    ...mapGetters('eventRegistration', ['getRegistrantDetailsWithCart', 'getInputData'])
  },
  mounted() {
    this.list = _.cloneDeep(this.getRegistrantDetailsWithCart)
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData', 'resetEventState']),
    ...mapActions('auth', ['loginUserAfterRegistration']),
    ...mapActions('cart', ['resetCartState']),
    select: function (index) {
      if (this.selectedIndex !== null && this.selectedIndex !== index) {
        this.list[this.selectedIndex].selected = false
      }
      this.list[index].selected = true
      this.selectedIndex = index
      this.saveInputData({
        inputs: {payingID: this.list[index].payingID}
      })
      this.$forceUpdate()
    },
    loadCardStuff: function () {
      axios.post('registration/post', {
        event: this.getEventDetails.id,
        input: this.getInputData,
        cart_id: this.getCart.id
      })
          .then((resp) => {
            if (resp.data.free && resp.data.bankURL === '') {
              this.loginUserAfterRegistration(resp.data.userDetails)
                  .then(() => {
                    this.resetEventState()
                    this.resetCartState()
                    this.$router.push({'name': 'profile.registrations'})
                  })
            } else {
              this.loginUserAfterRegistration(resp.data.userDetails)
                  .then(() => {
                    window.location = resp.data.bankURL
                  })
            }
          })
    }
  }
}
</script>

<style scoped>

</style>