<template>
  <div class="inline-block p-2" :class="'w-' + field.size"
       v-show="!field.quantity || field.quantity && field.quantity > field.values_count">
    <text-field-display v-if="field.type === 'text'" :field="field" :form="form"></text-field-display>
    <date-field-display v-if="field.type === 'date'" :field="field" :form="form"></date-field-display>
    <checkbox-field-display v-if="field.type === 'checkbox'" :field="field" :form="form"></checkbox-field-display>
    <radio-field-display v-if="field.type === 'radio_group'" :field="field" :form="form"></radio-field-display>
    <quantity-field-field-display v-if="field.type === 'quantityField'" :field="field" :form="form"></quantity-field-field-display>
    <club-field-display v-if="field.type === 'club_field'" :field="field" :form="form"></club-field-display>
    <other-documents-upload v-if="field.type === 'other_documents'" :field="field" :form="form"></other-documents-upload>
    <parental-documents-upload v-if="field.type === 'parental'" :field="field" :form="form"></parental-documents-upload>
    <medical-documents-upload v-if="field.type === 'medical_licence'" :field="field" :form="form"></medical-documents-upload>
    <select-field-display v-if="field.type === 'select'" :field="field" :form="form"></select-field-display>
    <tshirt-field-display v-if="field.type === 'tshirt_field'" :form="form" :field="field"></tshirt-field-display>
    <donation-field-display v-if="field.type === 'donation_field'" :field="field" :form="form"></donation-field-display>
    <medical-conditions-field-display v-if="field.type === 'medical_conditions'" :field="field" :form="form"></medical-conditions-field-display>
    <accessory-field-display v-if="field.type === 'accessory'" :field="field" :form="form"></accessory-field-display>
    <meal-field-display v-if="field.type === 'meal'" :field="field" :form="form"></meal-field-display>
    <nationality-field-display v-if="field.type === 'nationality'" :field="field" :form="form"></nationality-field-display>
    <emergency-contact-name-field-display v-if="field.type === 'emergency_contact_name'" :field="field" :form="form"></emergency-contact-name-field-display>
    <emergency-contact-phone-field-display v-if="field.type === 'emergency_contact_phone'" :field="field" :form="form"></emergency-contact-phone-field-display>
  </div>
</template>

<script>
import TextFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/TextFieldDisplay'
import DateFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/DateFieldDisplay'
import RadioFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/RadioFieldDisplay'
import CheckboxFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/CheckboxFieldDisplay'
import QuantityFieldFieldDisplay
  from '@/components/event/eventRegistration/Details/Partials/Fields/QuantityFieldFieldDisplay'
import ClubFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/ClubFieldDisplay'
import OtherDocumentsUpload from '@/components/event/eventRegistration/Details/Partials/Fields/OtherDocumentsUpload'
import ParentalDocumentsUpload
  from '@/components/event/eventRegistration/Details/Partials/Fields/ParentalDocumentsUpload'
import MedicalDocumentsUpload
  from '@/components/event/eventRegistration/Details/Partials/Fields/MedicalDocumentsUpload'
import SelectFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/SelectFieldDisplay'
import TshirtFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/TshirtFieldDisplay'
import DonationFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/DonationFieldDisplay'
import MedicalConditionsFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/MedicalConditionsFieldDisplay'
import AccessoryFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/AccessoryFieldDisplay'
import MealFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/MealFieldDisplay'
import NationalityFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/NationalityFieldDisplay'
import EmergencyContactNameFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/EmergencyContactNameFieldDisplay'
import EmergencyContactPhoneFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/EmergencyContactPhoneFieldDisplay'

export default {
  name: 'MasterFieldDisplay',
  components: {
    NationalityFieldDisplay,
    SelectFieldDisplay,
    MedicalDocumentsUpload,
    ParentalDocumentsUpload,
    OtherDocumentsUpload,
    ClubFieldDisplay,
    QuantityFieldFieldDisplay,
    CheckboxFieldDisplay,
    TextFieldDisplay,
    DateFieldDisplay,
    RadioFieldDisplay,
    TshirtFieldDisplay,
    DonationFieldDisplay,
    MedicalConditionsFieldDisplay,
    AccessoryFieldDisplay,
    MealFieldDisplay,
    EmergencyContactNameFieldDisplay,
    EmergencyContactPhoneFieldDisplay,
  },
  props: ['field', 'form']
}
</script>

<style scoped>

</style>