<template>
  <div>
    <label class="w-full inline-block">
      <input
          type="checkbox"
          v-model="value"
          :placeholder="field.placeholder"
      >
      {{ field.title }}
      <span v-if="field.price && field.price.price"><price v-model="field.price.display_price"></price></span>
    </label>
    <p class="text-red-600 error-message" v-if="getErrorDetails.errors && getErrorDetails.errors[errorKey]">
      {{ getErrorDetails.errors[errorKey][0] }}
    </p>
    <p v-if="field.helper">{{ field.helper }}</p>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CheckboxFieldDisplay',
  props: ['field', 'form'],
  data: function () {
    return {
      value: ''
    }
  },
  computed: {
    ...mapGetters('eventRegistration', ['getErrorDetails']),
    errorKey: function () {
      return 'inputs.' + this.form.id + '.' + this.field.key
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.saveInputData({
          form: this.form,
          field: this.field.key, id: this.field.id,
          price: this.field.price?.price,
          value: this.value
        })
      }
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>