<template>
  <div>
    <div>
      <master-field-display v-for="field in form.data.fields[fieldsGroup]"
                            :field="field"
                            :form="form"
                            :value="inputs[field.key]"
                            v-bind:key="form.id + field.id"></master-field-display>
    </div>

    <div class="flex">
      <previous-button-action :form="form" :enabled="true"></previous-button-action>
      <next-button-action :form="form"
                          :enabled="true"
                          :requires-validation="true"
                          :validating-fields="this.validatedFields"
      ></next-button-action>
    </div>
  </div>
</template>

<script>
import MasterFieldDisplay from '@/components/event/eventRegistration/Details/Partials/Fields/MasterFieldDisplay'
import NextButtonAction from '@/components/event/eventRegistration/Details/Partials/NextButtonAction'
import PreviousButtonAction from '@/components/event/eventRegistration/Details/Partials/PreviousButtonAction'
import {mapActions} from 'vuex'

export default {
  name: 'FormParentOthers',
  props: ['form'],
  components: {NextButtonAction, MasterFieldDisplay, PreviousButtonAction},
  data: function () {
    return {
      fieldsGroup: 2,
      inputs: {},
      validatedFields: []
    }
  },
  created () {
    for (let i = 0; i < this.form.data.fields[this.fieldsGroup].length; i++) {
      let data = this.form.data.fields[this.fieldsGroup][i]
      this.inputs[data.key] = ''
      this.validatedFields.push(data.id)
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveInputData'])
  }
}
</script>

<style scoped>

</style>