<template>
  <div>
    <div v-if="getCart" class="mt-4">
      <h1 class="mb-4">Cart review</h1>
      <div v-for="item in registrationsList" v-bind:key="item.id + item.payingID" class="pb-4 border-b pt-4">
        <div class="flex">
          <div class="w-1/3">Registrant: <strong>{{ item.registrant.last_name }} {{
              item.registrant.first_name
            }}</strong>
          </div>
          <div class="w-1/3 text-center">{{ item.title }}</div>
          <div class="w-1/3 text-right">
            <price v-model="item.original_price"></price>
          </div>
        </div>
        <div class="flex" v-for="(option, index) in item.options" v-bind:key="'option' + index">
          <div class="w-1/3"></div>
          <div class="w-1/3 text-center">{{ option.title }}</div>
          <div class="w-1/3 text-right">
            <price v-model="option.subtotal"></price>
          </div>
        </div>
        <div class="flex" v-if="item.discount_amount">
          <div class="w-1/3"></div>
          <div class="w-1/3 text-center">{{ $t('discount') }}</div>
          <div class="w-1/3 text-right">
            -
            <price v-model="item.discount_amount"></price>
          </div>
        </div>
        <div class="flex" v-if="item.reduction_amount">
          <div class="w-1/3"></div>
          <div class="w-1/3 text-center">{{ $t('reduction') }}</div>
          <div class="w-1/3 text-right">
            -
            <price v-model="item.reduction_amount"></price>
          </div>
        </div>
      </div>
      <div class="flex border-b" v-show="!getEventDetails.fees_included">
        <div class="w-1/3"> {{ $t('fees') }}</div>
        <div class="w-1/3 text-center"></div>
        <div class="w-1/3 text-right">
          <price v-model="getCart.total_fees"></price>
        </div>
      </div>
      <div class="w-full text-right mt-4 pt-4">
        {{ $t('total') }}:
        <price v-model="getCart.total_price"></price>
      </div>
    </div>
    <discount-code></discount-code>
    <div class="mt-8">
      <label>
        <input type="checkbox" name="terms" v-model="termsAccepted"> I accept terms and conditions
      </label>
    </div>
    <div v-if="termsAccepted" class="mt-8">
      <next-button-action :enabled="true"></next-button-action>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import DiscountCode from '@/components/event/cart/DiscountCode'
import NextButtonAction from '@/components/event/eventRegistration/Details/Partials/NextButtonAction'

export default {
  name: 'PaymentOverviewPage',
  components: {DiscountCode, NextButtonAction},
  data: function () {
    return {
      termsAccepted: false
    }
  },
  computed: {
    ...mapGetters('cart', ['getCart']),
    ...mapGetters('eventRegistration', ['getInputData']),
    ...mapGetters('event', ['getEventDetails']),
    registrationsList: function () {
      /*
      This converts the list of users that are tying to register to match their cart pricing for forms.
       */
      const list = []
      _.map(this.getCart.items, (item, index) => {
        item.registrant = this.getInputData[index]
        item.payingID = index
        list.push(_.cloneDeep(item))
      })
      this.saveRegistrantDetailsWithCart(list)
      return list
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['saveRegistrantDetailsWithCart'])
  }
}
</script>

<style scoped>

</style>