<template>
  <div>
    <h2 class="text-4xl mb-8">{{ form.title }}</h2>

    <form-overview-page :form="form" v-show="getCurrentSubStep === 'overview'"></form-overview-page>
    <form-parent-group-team :form="form" v-show="getCurrentSubStep === 'team'"></form-parent-group-team>
    <form-parent-others :form="form" v-show="getCurrentSubStep === 'other'"></form-parent-others>
    <form-contacts-page :form="form" v-show="getCurrentSubStep === 'contacts'"></form-contacts-page>
    <form-identity-page :form="form" v-show="getCurrentSubStep === 'identity'"></form-identity-page>
    <form-address-page :form="form" v-show="getCurrentSubStep === 'address'"></form-address-page>
    <form-document-upload :form="form" v-show="getCurrentSubStep === 'documents'"></form-document-upload>
    <form-options-page :form="form" v-show="getCurrentSubStep === 'options'"></form-options-page>
    <form-terms-page :form="form" v-show="getCurrentSubStep === 'terms'"></form-terms-page>
    <form-club-page :form="form" v-show="getCurrentSubStep === 'club'"></form-club-page>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormOverviewPage from '@/components/event/eventRegistration/Details/Partials/FormOverviewPage'
import FormParentGroupTeam from '@/components/event/eventRegistration/Details/Partials/FormParentGroupTeam'
import FormContactsPage from '@/components/event/eventRegistration/Details/Partials/FormContactsPage'
import FormIdentityPage from '@/components/event/eventRegistration/Details/Partials/FormIdentityPage'
import FormAddressPage from '@/components/event/eventRegistration/Details/Partials/FormAddressPage'
import FormTermsPage from '@/components/event/eventRegistration/Details/Partials/FormTermsPage'
import FormDocumentUpload
  from '@/components/event/eventRegistration/Details/Partials/FormDocumentUpload'
import FormOptionsPage from '@/components/event/eventRegistration/Details/Partials/FormOptionsPage'
import FormParentOthers from '@/components/event/eventRegistration/Details/Partials/FormParentOthers'
import FormClubPage from '@/components/event/eventRegistration/Details/Partials/FormClubPage'
import EventBus from '@/events/eventBus'

export default {
  name: 'FormDisplay',
  components: {
    FormClubPage,
    FormParentOthers,
    FormOptionsPage,
    FormDocumentUpload,
    FormIdentityPage,
    FormContactsPage,
    FormParentGroupTeam,
    FormOverviewPage,
    FormAddressPage,
    FormTermsPage
  },
  props: ['form', 'index'],
  computed: {
    ...mapGetters('eventRegistration', ['getCurrentSubStep'])
  },
  created () {
    if (this.form.data.form_id) {
      this.saveInputData({
        form: this.form,
        field: 'parent_id',
        value: this.form.data.form_id
      })
    }
  },
  methods: {
    ...mapActions('eventRegistration', ['setSubStep', 'moveToNextForm', 'saveInputData']),
    forceCartUpdate () {
      EventBus.$emit('cart/reload')
    }
  }
}
</script>

<style scoped>

</style>